import Vue from 'vue';
import VueRouter from 'vue-router';
import { Auth } from 'aws-amplify';

import { getMeta } from '@/router/utils';

import applyOnRouterAbortShim from './router-abort-shim';
import { authRoutes } from './auth';
import { clarityRoutes } from './clarity';
import { tenantRoutes } from './tenant';

const Home = () => import('@/imports/ui/pages/home.vue');
const Authenticated = () => import('@/modules/pageLayout').then(({ Authenticated }) => Authenticated);

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    name: 'home',
    component: Home,
    meta: { requiresAuth: true, clarity: true },
  },
  ...authRoutes,
  {
    path: '/requests/:id',
    redirect: (to) => {
      const tenantId = '5ee07c285f7ed4b0d4ac5236';

      return {
        path: `/${tenantId}/requests`,
        query: { requestId: to.params.id },
      };
    },
  },
  {
    path: '/:tenantId',
    props: true,
    component: Authenticated,
    children: [...clarityRoutes, ...tenantRoutes],
    meta: { requiresAuth: true },
  },
  {
    path: '*',
    redirect: '/not-found',
  },
];

const router = new VueRouter({ mode: 'history', routes });

applyOnRouterAbortShim(router);

router.beforeEach((to, _, next) => {
  const meta = getMeta(to.matched);
  switch (true) {
    case meta.requiresAuth:
      return Auth.currentAuthenticatedUser()
        .then(() => next())
        .catch(() => next({ name: 'signin', query: { redirect: to.fullPath } }));
    default:
      next();
  }
});

export default router;
