import { OperationsOverview, OperationsOverviewByBusiness } from '@/modules/operations';

// Dynamic imports(Lazy loading)
const Users = () => import('@/modules/users/Users.vue');
const Restaurants = () => import('@/modules/restaurants/Restaurants.vue');
const Businesses = () => import('@/modules/businesses/BusinessesPage.vue');
const Suppliers = () => import('@/modules/suppliers/Suppliers.vue');
const SupplierItems = () => import('@/modules/suppliers/SupplierItems.vue');
const DocumentsOperationOverview = () =>
  import('@/modules/document').then(({ DocumentsOperationOverview }) => DocumentsOperationOverview);
const AutomationManagement = () =>
  import('@/modules/automation').then(({ AutomationManagement }) => AutomationManagement);
const AutomationManagementDocuments = () =>
  import('@/modules/automation').then(({ AutomationManagementDocuments }) => AutomationManagementDocuments);
const TemplateManagement = () => import('@/modules/automation').then(({ TemplateManagement }) => TemplateManagement);
const Products = () => import('@/modules/product').then(({ Products }) => Products);
const PriceIndexes = () => import('@/modules/price-indexes/PriceIndexes.vue');
const PriceIndex = () => import('@/modules/price-indexes/PriceIndex.vue');
const Requests = () => import('@/modules/requests/serviceProvider').then(({ Requests }) => Requests);
const OpenRequestsServiceProvider = () =>
  import('@/modules/requests').then(({ OpenRequestsServiceProvider }) => OpenRequestsServiceProvider);
const ClosedRequestsServiceProvider = () =>
  import('@/modules/requests').then(({ ClosedRequestsServiceProvider }) => ClosedRequestsServiceProvider);
const InReviewRequestsServiceProvider = () =>
  import('@/modules/requests').then(({ InReviewRequestsServiceProvider }) => InReviewRequestsServiceProvider);
const ContactsSetPage = () => import('@/modules/contactSet/contactSets.vue');
const TasksPage = () => import('@/modules/tasks/TasksPage.vue');
const DefineProductTask = () => import('@/modules/product/components/task/DefineProductTask.vue');

const EmptyComponent = {
  render(h) {
    return h('div', ['EmptyComponent']);
  },
};

export const clarityRoutes = [
  {
    path: '/',
    name: 'clarity',
    redirect: { name: 'clarity.users' },
    component: {
      render: (h) => h('router-view'),
    },
    children: [
      { path: 'users', name: 'clarity.users', component: Users },
      { path: 'restaurants', name: 'clarity.restaurants', component: Restaurants },
      { path: 'businesses', name: 'clarity.businesses', component: Businesses },
      { path: 'suppliers', name: 'clarity.suppliers', component: Suppliers },
      { path: 'suppliers/:supplierId', name: 'clarity.supplierItems', component: SupplierItems },
      { path: 'operation/documents', name: 'clarity.documents', component: DocumentsOperationOverview },
      { path: 'automationManagement', name: 'automationManagement', component: AutomationManagement },
      {
        path: 'automationManagement/documents',
        name: 'automationManagementDocuments',
        component: AutomationManagementDocuments,
      },
      {
        path: 'automationManagement/documents/templates',
        name: 'automationTemplateManagement',
        component: TemplateManagement,
      },
      { path: 'products', name: 'clarity.products', component: Products },
      {
        path: 'insights',
        name: 'clarity.insights',
        component: EmptyComponent,
      },
      { path: 'price-indexes', name: 'clarity.priceIndexes', component: PriceIndexes },
      { path: 'price-indexes/:priceIndexId', name: 'clarity.priceIndex', component: PriceIndex },
      { path: 'operations', name: 'operations', component: OperationsOverview },
      { path: 'operations-by-business', name: 'operationsByBusiness', component: OperationsOverviewByBusiness },
      {
        path: 'requests/',
        name: 'requests',
        component: Requests,
        children: [
          { path: 'open', name: 'open', props: true, component: OpenRequestsServiceProvider },
          { path: 'in-review', name: 'inReview', props: true, component: InReviewRequestsServiceProvider },
          { path: 'closed', name: 'closed', props: true, component: ClosedRequestsServiceProvider },
        ],
      },
      { path: 'contact-sets', name: 'clarity.contactSets', component: ContactsSetPage },
      { path: 'tasks/', name: 'clarity.tasks', component: TasksPage },
      { path: 'tasks/:taskId', name: 'clarity.productTask', component: DefineProductTask },
    ],
  },
];
