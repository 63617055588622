import Settings from '@/modules/user/settings';
import { Activity, SupplierActivity } from '@/modules/activity';
import { PriceList, PriceListUpdate, PriceListsManagement } from '@/modules/price';
import { ReconciliationStatementReport, AgedDebtorsReport } from '@/modules/payment';
import {
  Requests,
  OpenRequestsSupplier,
  ClosedRequestsSupplier,
  InReviewRequestsSupplier,
} from '@/modules/requests/supplier';
import {
  SuppliersPurchaseManagementPage,
  SupplierProductsPurchaseManagementPage,
  TopPurchasedProducts,
  PriceChangeImplications,
  SuppliersSettingsPage,
  SupplierSettingsPage,
} from '@/modules/term';
import { DeliveryManagement, GoodsExchangeRecording } from '@/modules/delivery';
import { OrderProcess } from '@/modules/orderProcess';
import { BookkeepingManagement, SentDocuments } from '@/modules/bookkeeping';
import {
  BookkeeperDataTransferSummary,
  BookkeeperDataTransfer,
  BookkeeperUnexportedBillings,
  BookkeeperBillingsExports,
  BookkeeperBillingsExport,
  BookkeeperSettings,
} from '@/modules/bookkeeper';
import { ReconciliationTask, SupplierTask, UncertainBillingTask, BalanceAlignmentTask } from '@/modules/tasks';
import ContractRecording from '@/modules/suppliers/ContractRecording';
import { BusinessSettings } from '@/modules/business';
import { OrderProcessCart } from '@/modules/orderProcessCart';
import { OrderSettings } from '@/modules/orderProcess/components/settings';
import { Contacts } from '@/modules/contacts';
import { Permissions } from '@/modules/permissions';
import { loadLegacyView } from './utils';
import { operationManagementRoutes } from './operation-management';
import { ProductsSupplier } from '@/modules/product';
import { AlertsPage } from '@/modules/alert';

// Dynamic imports(Lazy loading)
const BillingManagement = () => import('@/modules/billing').then(({ BillingManagement }) => BillingManagement);
const DocumentsOverview = () => import('@/modules/document').then(({ DocumentsOverview }) => DocumentsOverview);
const RecordDocuments = () => import('@/modules/document').then(({ RecordDocuments }) => RecordDocuments);
const OrganizationTasks = () => import('@/modules/document').then(({ OrganizationTasks }) => OrganizationTasks);
const ClassificationTasks = () => import('@/modules/document').then(({ ClassificationTasks }) => ClassificationTasks);
const Payment = () => import('@/modules/payment').then(({ Payment }) => Payment);

export const tenantRoutes = [
  {
    path: '/',
    component: { render: (h) => h('router-view') },
    name: 'tenant',
    redirect: { name: 'overview' },
    children: [
      ...operationManagementRoutes,
      { path: 'overview', name: 'overview', component: loadLegacyView('pages/overview') },
      { path: 'settings', name: 'settings', component: Settings },
      {
        path: 'billing',
        name: 'billingManagement',
        component: BillingManagement,
        meta: { requiresAdmin: true },
      },
      { path: 'documents', name: 'documents', component: DocumentsOverview },
      {
        path: 'documents/record',
        name: 'recordDocuments',
        component: RecordDocuments,
        meta: { requiresAdmin: true },
      },
      {
        path: 'documents/organizeDocuments',
        name: 'organizeDocuments',
        component: OrganizationTasks,
        meta: { requiresAdmin: true },
      },
      {
        path: 'documents/classifyDocuments',
        name: 'classifyDocuments',
        component: ClassificationTasks,
        meta: { requiresAdmin: true },
      },
      { path: 'priceChangeImplications', name: 'priceChangeImplications', component: PriceChangeImplications },
      { path: 'terms', name: 'suppliersPurchaseManagement', component: SuppliersPurchaseManagementPage },
      { path: 'terms/settings', name: 'suppliersSettings', component: SuppliersSettingsPage },
      { path: 'terms/settings/:supplierId', name: 'supplierSettings', component: SupplierSettingsPage },
      { path: 'terms/top-purchased-products', name: 'topPurchasedProducts', component: TopPurchasedProducts },
      {
        path: 'terms/contract-recording',
        name: 'contractRecording',
        component: ContractRecording,
        meta: { requiresAdmin: true },
      },
      {
        path: 'terms/:supplierId',
        name: 'supplierProductsPurchaseManagement',
        component: SupplierProductsPurchaseManagementPage,
      },
      { path: 'activity', name: 'activity', component: Activity },
      {
        path: 'activity/operation-management/new-supplier/:taskId',
        name: 'supplierTasks',
        component: SupplierTask,
      },
      {
        path: 'activity/operation-management/reconciliation-task/:taskId',
        name: 'reconciliationTask',
        component: ReconciliationTask,
      },
      {
        path: 'activity/operation-management/uncertain-billing-task/:taskId',
        name: 'uncertainBillingTask',
        component: UncertainBillingTask,
      },
      {
        path: 'activity/operation-management/balance-alignment-task/:taskId',
        name: 'balanceAlignmentTask',
        component: BalanceAlignmentTask,
      },
      { path: 'activity/:supplierId', name: 'supplierActivity', component: SupplierActivity },
      {
        path: 'price-lists',
        name: 'priceListsManagement',
        component: PriceListsManagement,
      },
      {
        path: 'price-lists/:priceListId',
        name: 'priceList',
        component: PriceList,
      },
      {
        path: 'price-lists/update/:id',
        name: 'priceListsManagement.priceListUpdate',
        component: PriceListUpdate,
      },
      {
        path: 'delivery-management',
        name: 'deliveryManagement',
        component: DeliveryManagement,
      },
      {
        path: 'goods-exchange-recording',
        name: 'goodsExchangeRecording',
        component: GoodsExchangeRecording,
      },
      {
        path: 'payments/:businessId?',
        name: 'payments',
        component: Payment,
        props: true,
      },
      {
        path: 'payments/:supplierId/reconciliation',
        name: 'reconciliationStatementReport',
        component: ReconciliationStatementReport,
      },
      {
        path: 'payments/:supplierId/agedDebtorsReport',
        name: 'agedDebtorsReport',
        component: AgedDebtorsReport,
        props: true,
      },
      {
        path: 'bookkeeping',
        name: 'bookkeepingManagement',
        component: BookkeepingManagement,
      },
      {
        path: 'bookkeeper-data-transfer',
        name: 'bookkeeperDataTransferSummary',
        component: BookkeeperDataTransferSummary,
      },
      {
        path: 'bookkeeper-data-transfer/:customerId',
        name: 'bookkeeperDataTransferForCustomer',
        component: BookkeeperDataTransfer,
        redirect: { name: 'unsent' },
        children: [
          { path: 'unsent', name: 'unsent', component: BookkeeperUnexportedBillings },
          { path: 'sent', name: 'sent', component: BookkeeperBillingsExports },
          { path: 'settings', name: 'settings', component: BookkeeperSettings },
        ],
      },
      {
        path: 'bookkeeper-data-transfer/:customerId/billings-export/:id',
        name: 'bookkeeperBillingsExport',
        component: BookkeeperBillingsExport,
      },
      {
        path: 'bookkeeping/batch/:id',
        name: 'bookkeepingBatch',
        component: SentDocuments,
      },
      {
        path: 'contacts',
        name: 'contacts',
        component: Contacts,
      },
      {
        path: 'permissions',
        name: 'permissions',
        component: Permissions,
      },
      {
        path: 'business-settings',
        name: 'businessSettings',
        component: BusinessSettings,
      },
      {
        path: 'orders',
        name: 'orders',
        component: OrderProcess,
      },
      {
        path: 'orders/settings',
        name: 'orderSettings',
        component: OrderSettings,
      },
      {
        path: 'orders/:orderId',
        name: 'orderItems',
        component: OrderProcessCart,
      },
      {
        path: 'requests-supplier',
        name: 'requests-supplier',
        component: Requests,
        redirect: { name: 'openRequestsSupplier' },
        children: [
          { path: 'open', name: 'openRequestsSupplier', component: OpenRequestsSupplier },
          { path: 'in-review', name: 'inReviewRequestsSupplier', component: InReviewRequestsSupplier },
          { path: 'closed', name: 'closedRequestsSupplier', component: ClosedRequestsSupplier },
        ],
      },
      { path: 'products-supplier', name: 'clarity.products-supplier', component: ProductsSupplier },
      { path: 'alerts', name: 'alerts', component: AlertsPage },
    ],
  },
];
